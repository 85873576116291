import $ from '../node_modules/jquery/dist/jquery.js';

// Open and close the optional navigation
$('.toggle').click(function () {
    $(this).toggleClass('open');
    $('.menu').toggleClass('open');
});

$('.menu .nav-icon').click(function () {
    $('.toggle').removeClass('open');
    $('.menu').removeClass('open');
});
